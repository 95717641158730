@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");


* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

main {
  /* background-color: #EEECD6; */
  background-color: "pink";
}

body {
  /* margin: 0;
  padding: 0; */
  font-family: 'Inter', sans-serif;
  background-color: #EEECD6 !important;
  /* height: 100vh;
  width: 100vw; */
}

nav {
  /* padding: 10px; */
  background-color: #86835E;
}

.nav--logo {
  font-family: 'Inter', sans-serif;
  color: #FEFAF8;
}


.guess--button {
  /* background-color: #86835E; */
  /* border-radius: 50%; */
}

.setup--icon {
  /* width: 20vh;
  height: 18vh; */
  margin: 2px;
  background-color: #D9D9D9;
}

.setup--container {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.setup--grid {
  display: flex;
}

.setup--tile {
  padding: 20px;
  cursor: pointer;
  justify-content: top;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.setup--icon {
  border-radius: 20px;
  background: #D9D9D9;
  margin-bottom: 15px;
  overflow: hidden;
  object-fit: contain;
}

.setup--image {
  border-radius: 20px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  /* border-radius: 20px;
  max-width: 100%; */
}

.setup--cardcontent {
  background-color: #EEECD6;
}

.setup--descriptor {
  font-weight: 600 !important;
  color: #273526;
}

.scoring--credits {
  font-weight: 400 !important;
  color: #8c918c;
}

.guess--img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  background-color: #2E2E2E;
}

.scoring--img {
  object-fit: contain;
  max-height: 100%;
  background-color: #2E2E2E;
}

.guess--stats {
  display: flex;
}
/* 
.header--large {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: #273526;
  font-size: 2em;
} */

.header--large {
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #273526;
}

